import React from 'react';
import { graphql } from 'gatsby';
import '../../fragments.ts';
import PortalForm from '../../components/ui/PortalForm';
import { IndexPageProps } from '.';

import * as styles from './form.module.scss';

export const query = graphql`
  {
    sanityHomepage {
      ...Homepage
    }
    sanityVedicRisingSigns {
      ...VedicRisingSigns
    }
  }
`;

const FormPage = ({ data }: IndexPageProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <PortalForm
        birthdayScreen={data.sanityHomepage.birthDataScreen}
        resultScreen={data.sanityHomepage.resultScreen}
        shareScreen={data.sanityHomepage.shareScreen}
        vedicRisingSigns={data.sanityVedicRisingSigns}
      />
    </div>
  );
};

export default FormPage;
